<script>
export default {
  name: "acc-watching",

  metaInfo: {
    title: "Guardados",
  },
};
</script>

<template>
  <sitio-layout id="watching">
    <div class="content_width page_gap">
      <div class="page__header">
        <h1 class="page__title">Guardadas</h1>
      </div>
      <div class="content">
        <p>No sigues ninguna publicación.</p>
        <!-- <p>Cuando contactes, los mensajes de su anfitrión aparecerán aquí.</p> -->
      </div>
    </div>
  </sitio-layout>
</template>

<style lang="scss" scoped>
.page__header {
  margin-bottom: 1.5rem;
}
</style>
